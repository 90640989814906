import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faHouse, faBroom, faCalendar, faBars } from '@fortawesome/free-solid-svg-icons';
import profilePic from "../images/148.png";
import TimeSlider from '../components/TimeSlider';
import logo from "../images/FreshMClean-logo PNG 2.png";

export default function Setavailability() {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state?.userData;
  const [activeTab, setActiveTab] = useState('Sunday'); // Default to Sunday
  const [inactiveTabs, setInactiveTabs] = useState([]); // Track inactive tabs
  const [offButtons, setOffButtons] = useState([]); // Track off buttons

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const toggleInactive = (day) => {
    setInactiveTabs((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
    setOffButtons((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleDelete = (day) => {
    setInactiveTabs((prev) => [...prev, day]);
    setOffButtons((prev) => [...prev, day]);
  };

  return (
    <div>
      <nav className="dashboard-nav">
      <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers">
            Offers
            {/* <span className="alert">4</span> */}
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More" className='indicator'>More</Link>
        </div>
      </nav>

      <div className="more-body">
        <div className="more-header">
          <span onClick={() => navigate('/More')}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className="profile-display">
            <figure>
              <img src={profilePic} alt="" />
            </figure>
            <p>{userData ? userData.fullname : "New User"}</p>
          </div>
        </div>
        <div className="more-space">
          <div className="availability-space">
            <h1>My Hours</h1>
            <div className="as-header">
              <h4>Time and start day</h4>
              <small>Select a start day and a time slot</small>
            </div>

            <div className="as-tab-header">
              {daysOfWeek.map((day) => (
                <span
                  key={day}
                  className={activeTab === day ? 'active' : ''}
                  onClick={() => setActiveTab(day)}
                >
                  {day.slice(0, 3)}
                </span>
              ))}
            </div>

            {daysOfWeek.map((day) => (
              activeTab === day && (
                <section key={day} className={`day-tab ${activeTab === day ? 'active' : ''} ${inactiveTabs.includes(day) ? 'inactive' : ''}`}>
                  <div className="day-tab-header">
                    <h2 className="aday">{activeTab}</h2>
                    <div
                      onClick={() => toggleInactive(day)}
                      className={`dth-switch ${offButtons.includes(day) ? 'off-btn' : ''}`}
                    >
                      <span></span>
                    </div>
                  </div>
                  {!inactiveTabs.includes(day) && (
                    <div className="availability-tab-content">
                      <div className="atc-header">
                        <h4>Start and end time</h4>
                        <small>What is the earliest and latest time you want to work?</small>
                      </div>
                      <div className="atc-time-span">
                        <TimeSlider />
                      </div>
                      <div className="atc-buttons">
                        <span>Submit</span>
                        <span className='atc-delete' onClick={() => handleDelete(day)}>Delete</span>
                      </div>
                    </div>
                  )}
                </section>
              )
            ))}
          </div>
        </div>
      </div>

      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          {/* <span>4</span> */}
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More" className='mn-indicator'>
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}