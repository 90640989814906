import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function Notifications() {

    const navigate =useNavigate();

    const toBack = () => {
        navigate("/Dashboard")
    }

  return (
    <div>
        <nav className="dashboard-nav nd-nav">
            <h3 className="notification-logo">
                <FontAwesomeIcon icon={faChevronLeft} onClick={toBack}/>
                <p>
                    Notifications
                </p>
            </h3>
            <div className="dn-links">
                <Link to="/Dashboard" className='indicator'>Home</Link>
                <Link to="/Offers">
                    Offers
                    {/* <span className="alert">
                        4
                    </span> */}
                </Link>
                <Link to="/Calendar">Calendar</Link>
                <Link to="/More">More</Link>
            </div>
        </nav>
        <div className="notification-space">
            <h3 className="notifications-subheading">
                Important information
            </h3>
            <div className="notifications">
                <Link to="/More" className='notification'>
                    <div>
                        <p>New: Share your profile now and get new references</p>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Link>
                <Link to="/References" className='notification'>
                    <div>
                        <p>Request References</p>
                        <small>
                            Optimize your profile by requesting references from past employers or customers
                        </small>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Link>
                <Link to="/References" className='notification'>
                    <div>
                        <p>Request References</p>
                        <small>
                            Optimize your profile by requesting references from past employers or customers
                        </small>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Link>
            </div>
        </div>
    </div>
  )
}
