import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronDown, faChevronUp, faChevronLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function Help() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  return (
    <div className='help-page'>
      <div className="help-nav">
        <FontAwesomeIcon icon={faChevronLeft} 
        onClick={() => navigate('/More')}
        className='hn-back'/>
        <h3>Help & FAQs</h3>
        <span></span>
      </div>

      <div className="help-space">
        <div className='hs-wrapper'>
          <div className="help-search">
            <h2>
              Got questions ?
            </h2>
            <div className="hs-space">
              <input type="search" placeholder='Search for help' />
              <span>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
            </div>
          </div>

          <div className="accordion-space">
            <section className="accordion">
              <div className="accordion-head" onClick={() => toggleAccordion(0)}>
                <p>How can I delete my profile</p>
                <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronDown} />
              </div>
              <div className={`accordion-content ${activeIndex === 0 ? 'open' : ''}`}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate corporis recusandae tenetur mollitia quia pariatur deserunt et alias praesentium molestiae vitae quaerat iure harum doloremque quos quod sequi natus, voluptas voluptatibus saepe numquam ex minima voluptates sapiente? Nam commodi consectetur dolor iure ratione, a ducimus totam voluptas, fuga, exercitationem illum!
              </div>
            </section>
            <section className="accordion">
              <div className="accordion-head" onClick={() => toggleAccordion(1)}>
                <p>How can I withdraw my balance</p>
                <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronDown} />
              </div>
              <div className={`accordion-content ${activeIndex === 1 ? 'open' : ''}`}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate corporis recusandae tenetur mollitia quia pariatur deserunt et alias praesentium molestiae vitae quaerat iure harum doloremque quos quod sequi natus, voluptas voluptatibus saepe numquam ex minima voluptates sapiente? Nam commodi consectetur dolor iure ratione, a ducimus totam voluptas, fuga, exercitationem illum!
              </div>
            </section>
            <section className="accordion">
              <div className="accordion-head" onClick={() => toggleAccordion(2)}>
                <p>How can I send a referral link</p>
                <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronDown} />
              </div>
              <div className={`accordion-content ${activeIndex === 2 ? 'open' : ''}`}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate corporis recusandae tenetur mollitia quia pariatur deserunt et alias praesentium molestiae vitae quaerat iure harum doloremque quos quod sequi natus, voluptas voluptatibus saepe numquam ex minima voluptates sapiente? Nam commodi consectetur dolor iure ratione, a ducimus totam voluptas, fuga, exercitationem illum!
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
