import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faHandshake,
  faCalendar,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHouse,
  faBroom,
  faBars,
  faFilter,
  faChevronLeft,
  faChevronRight,
  faLocationDot,
  faChevronDown,
  faChevronUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from 'react-loader-spinner';
import axiosInstance from "../context/axiosInstance";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../images/FreshMClean-logo PNG 2.png"


export default function CleaningOffers() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isPaymentSectionVisible, setPaymentSectionVisible] = useState(false);
  const [isCountdownModalVisible, setCountdownModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const offer = location.state?.offer;

  const togglePaymentSection = () => {
    setPaymentSectionVisible(!isPaymentSectionVisible);
  };

  const showCountdownModal = () => {
    setCountdownModalVisible(true);
  };

  const hideCountdownModal = () => {
    setCountdownModalVisible(false);
  };

  const handleAcceptOffer = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const cleanerId = localStorage.getItem('uid');
      const response = await axiosInstance.post("/cleanerPickJob", {
        cleanerId,
        orderId: offer._id,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        toast.success("Offer accepted successfully!");
        navigate("/Offers");
      } else {
        throw new Error('Failed to accept the offer');
      }
    } catch (error) {
      toast.error("Error accepting the offer. Please try again.", {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!offer) {
    return <p>No offer data available</p>;
  }

  return (
    <div className="co-page">
      <nav className="dashboard-nav">
        <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers" className="indicator">
            Offers
            <span className="alert">4</span>
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More">More</Link>
        </div>
      </nav>
      <div className="cleaning-offers-header">
        <Link to="/Offers">
          <FontAwesomeIcon icon={faChevronLeft} />
        </Link>
        <h2>Cleaning Offers</h2>
        <span></span>
      </div>

      <div className="offers-space">
        <div className="offer-description">
          <section>
            <FontAwesomeIcon icon={faLocationDot} />
            <p>{offer.address.postCode}</p>
            <Link>View on map</Link>
          </section>
          <section>
            <FontAwesomeIcon icon={faCalendar} />
            <p>{new Date(offer.desireDate).toDateString()}</p>
          </section>
          <section>
            <FontAwesomeIcon icon={faClock} />
            <p>{offer.desireTime} | {offer.orderRange}</p>
          </section>
        </div>

        <div className="offer-countdown" onClick={showCountdownModal}>
          <span>
            <FontAwesomeIcon icon={faClock} className="oc-icon" />
            <p>Countdown Offer</p>
          </span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>

        {isCountdownModalVisible && (
          <div className="ocm-wrapper">
            <div className="offer-countdown-modal">
              <div className="ocm-header">
                <span></span>
                <p>Countdown Offers</p>
                <FontAwesomeIcon icon={faXmark} onClick={hideCountdownModal} />
              </div>
              <FontAwesomeIcon icon={faClock} className="oc-icon" />
              <small>
                Tired of missing offers? You now have 12 hours to request them
              </small>
              <h2>How it works</h2>
              <ul>
                <li>
                  The Helpling platform is sending you offers from customers.
                  Note: you have not been selected yet, but your profile matches
                  the offers.
                </li>
                <li>
                  Do you see offers when you are available? Great, request them!
                </li>
                <li>
                  Within 12 hours, you will be notified if you get the offer.
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="offer-booking-space">
          <h3>Booking Details</h3>
          <div className="booking-details">
            <section>
              <p>Customer ratings</p>
              <FontAwesomeIcon icon={faChevronRight} />
            </section>
            <section>
              <p>Duration</p>
              <small>2 Hours</small>
            </section>
            <section>
              <p>Travel time from home</p>
              <p>Show travel time</p>
            </section>
            <section className="booking-payment" onClick={togglePaymentSection}>
              <p>Booking Payment</p>
              <p>
                {offer.payment.amount.toFixed(2)}€{"  "}
                <FontAwesomeIcon
                  icon={isPaymentSectionVisible ? faChevronUp : faChevronDown}
                />
              </p>
            </section>
            {isPaymentSectionVisible && (
              <div className="booking-payment-section">
                <div>
                  <p>Customer price per hour</p>
                  <small>{(offer.payment.amount / 2).toFixed(2)} €</small>
                </div>
                <div>
                  <p>Helpling commission</p>
                  <small>-{(offer.payment.amount / 2 * 0.25).toFixed(2)} €</small>
                </div>
                <div>
                  <p>Payout after commission per hour</p>
                  <small>{(offer.payment.amount / 2 * 0.75).toFixed(2)} €</small>
                </div>
                <div>
                  <p>Payout after commission for 2 hours</p>
                  <small>{offer.payment.amount.toFixed(2)} €</small>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="offer-availability">
          <span></span>
          <p>Are you available?</p>
          <span></span>
        </div>

        <div className="availability-btns">
          <span onClick={handleAcceptOffer}>
            {isLoading ? <ThreeDots color="#00BFFF" height={10} width={30} /> : 'Accept'}
          </span>
          <span>Decline</span>
        </div>
      </div>

      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers" className="mn-indicator">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More">
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}
