import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

export default function Forgotpassword() {

    const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate('/Login')
  }

  return (
    <div>
        <div className="top-space">
            KleanUp Staff
        </div>
        <h1 className="reg-subheading">
            Forgot Password
        </h1>
        <form onSubmit={handleSubmit}className="registration-form">
            <label>
                Enter Email or Fullname
                <input type="text"/>
            </label>
            <label>
                Password
                <div className="password-space">
                    <input type={passwordVisible ? "text" : "password"} />
                    <FontAwesomeIcon 
                        icon={passwordVisible ? faEyeSlash : faEye} 
                        onClick={togglePasswordVisibility} 
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </label>
            <label>
                Confirm Password
                <div className="password-space">
                    <input type={passwordVisible ? "text" : "password"} />
                    <FontAwesomeIcon 
                        icon={passwordVisible ? faEyeSlash : faEye} 
                        onClick={togglePasswordVisibility} 
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </label>
            <button type="submit">Change password</button>
        </form>
    </div>
  )
}
