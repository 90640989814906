import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faHandshake,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHouse,
  faBroom,
  faBars,
  faBriefcase,
  faArrowRight,
  faCircleQuestion,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../context/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import logo from "../images/FreshMClean-logo PNG 2.png"
import NavBar from "../components/navBar";

export default function Dashboard() {
  const [earningsDescriptionVisible, setEarningsDescriptionVisible] =
    useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("uid"); // Retrieve the user ID from localStorage
        const response = await axiosInstance.get(`/cleaners/${userId}`);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const toggleEarningsDescription = () => {
    setEarningsDescriptionVisible(!earningsDescriptionVisible);
  };

  const hideEarningsDescription = () => {
    setEarningsDescriptionVisible(false);
  };

  const handleNotificationClick = () => {
    navigate("/Notifications");
  };
  const handleReferencesClick = () => {
    navigate("/References");
  };

  return (
    <div className="dashboard">
     <NavBar/>
      <div className="body">
        <header className="dashboard-header">
          <h3 className="welcome-message">
          Hello,
            {userData ? (
              ` ${userData.fullname}!`
            ) : (
              <div className="loader-container1">
                <ThreeDots color="#FFFFFF" height={40} width={40} />
              </div>
            )}
          </h3>

          <div className="dh-icons">
            <FontAwesomeIcon
              icon={faHandshake}
              className="dhi"
              onClick={handleReferencesClick}
            />
            <FontAwesomeIcon
              icon={faBell}
              className="dhi"
              onClick={handleNotificationClick}
            />
          </div>
        </header>
        <main>
          <div className="get-references">
            <span>
              <FontAwesomeIcon icon={faUsers} className="get-ref-icon" />
              <p>
                Customers are 25% more likely to select providers with
                references
              </p>
            </span>
            <Link to="/References">Get References</Link>
          </div>
          <div className="my-earnings">
            <div className="my-earnings-nav">
              <p className="me-subheading">
                <span>My Earnings</span>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className="earning-dropdown-btn"
                  onClick={toggleEarningsDescription}
                />
              </p>
              <Link to="/History">View history</Link>
            </div>
            {earningsDescriptionVisible && (
              <div className="my-earnings-description">
                <span>
                  <h5>Current earnings</h5>
                  <small>
                    This is the amount of all your completed events so far.
                  </small>
                </span>
                <span>
                  <h5>Estimated earnings this month</h5>
                  <small>
                    This is the expected total amount for the current month,
                    based on the planned appointments in your calendar.
                  </small>
                </span>
                <span>
                  <h5>Estimated earnings in next month</h5>
                  <small>
                    This is the expected total amount for the following month,
                    based on the planned appointments in your calendar.
                  </small>
                </span>
                <h4 className="gotit-btn" onClick={hideEarningsDescription}>
                  Got it
                </h4>
              </div>
            )}
          </div>
          <div className="my-earnings-dashboard">
            <div className="current-earnings">
              <div>
                <p>Current earnings in July</p>
                <p>Updated: today</p>
              </div>
              <div className="earning-amount">
                <span>0.00</span>
                <span>€</span>
              </div>
            </div>
            <div className="estimated-earnings">
              Estimated earnings this month
              <div className="earning-amount">
                <span>0.00</span>
                <span>€</span>
              </div>
            </div>
            <div className="estimated-earnings">
              Estimated earnings in August
              <div className="earning-amount">
                <span>0.00</span>
                <span>€</span>
              </div>
            </div>
          </div>
          <div className="find-customers">
            <FontAwesomeIcon icon={faBriefcase} className="fc-icon" />
            <h4>No new requests, yet...</h4>
            <p>Easily find new customers on our offers page.</p>
            <Link to="/Offers">
              <span>Find Customers now</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </main>
      </div>
      <div className="mobile-nav">
        <Link to="/Dashboard" className="mn-indicator">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More">
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}
