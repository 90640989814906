import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import axiosInstance from '../context/axiosInstance';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    const errors = {};
    if (!email) errors.email = 'Email is required';
    if (!password) errors.password = 'Password is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/cleaner/login', { email, password });
      toast.success(response.data.message);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('uid', response.data.uid);
      navigate('/Dashboard');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="dashboard-nav">
      <img src={logo} alt="" className='logo-pic'/>
      </div>
      <h1 className="reg-subheading">
        Login
      </h1>
      <form onSubmit={handleSubmit} className="registration-form">
        <label>
          Enter Email Address
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderColor: errors.email ? 'red' : '' }}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </label>
        <label>
          Enter Password
          <div className="password-space">
            <input
              type={passwordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ borderColor: errors.password ? 'red' : '' }}
            />
            <FontAwesomeIcon 
              icon={passwordVisible ? faEyeSlash : faEye} 
              onClick={togglePasswordVisibility} 
              style={{ cursor: 'pointer' }}
            />
          </div>
          {errors.password && <p className="error-message">{errors.password}</p>}
        </label>
        <span>
          <Link to="/Forgotpassword">Forgot password?</Link>
        </span>
        <button type="submit" disabled={isLoading}>
          {isLoading ? (
            <div className="loader-container">
              <ThreeDots color="#FFFFFF" height={40} width={40} />
            </div>
          ) : (
            'Login'
          )}
        </button>
      </form>
    </div>
  );
}
