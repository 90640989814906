import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faHandshake,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHouse,
  faBroom,
  faBars,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faFaceMeh } from "@fortawesome/free-regular-svg-icons";
import axiosInstance from "../context/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function Offers() {
  const [activeTab, setActiveTab] = useState("cleaning");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [approvedCount, setApprovedCount] = useState(0);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axiosInstance.get("/getAllCleaningRequest");
        const approvedOffers = response.data.filter(
          (offer) => offer.status === "approved"
        );
        setOffers(approvedOffers);
        setApprovedCount(approvedOffers.length);
      } catch (err) {
        setError(err.message || "Error fetching offers");
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="dashboard">
      <nav className="dashboard-nav nd-nav">
        <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers" className="indicator">
            Offers
            {/* <span className="alert">4</span> */}
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More">More</Link>
        </div>
      </nav>
      <div className="offers-space">
        <div className="offers-subheading">
          <h3>Job Offers</h3>
          <FontAwesomeIcon icon={faFilter} />
        </div>
        <div className="offers-tab-heading">
          <div
            className={`cleaning-oth ${
              activeTab === "cleaning" ? "oth-active" : ""
            }`}
            onClick={() => handleTabClick("cleaning")}
          >
            <p>Cleaning</p>
            <span>{approvedCount}</span>
          </div>
          <div
            className={`services-oth ${
              activeTab === "services" ? "oth-active" : ""
            }`}
            onClick={() => handleTabClick("services")}
          >
            <p>More Services</p>
          </div>
        </div>
        {activeTab === "cleaning" && (
          <div className="cleaning-offers">
            {offers.length > 0 ? (
              offers.map((offer) => (
                <Link
                  to="/CleaningOffers"
                  state={{ offer }}
                  key={offer._id}
                  className="offer"
                >
                  <div className="offer-side1">
                    <h4>{new Date(offer.desireDate).toDateString()}</h4>
                    <p className="offer-duration">
                      {offer.desireTime} (6 hours)
                    </p>
                    <span>
                      <p>{offer.orderRange}</p>
                      <p> near {offer.address.postCode}</p>
                    </span>
                  </div>
                  <div className="earning-amount">
                    <span>{offer.payment.amount.toFixed(2)}</span>
                    <span>&#8358;</span>
                  </div>
                </Link>
              ))
            ) : (
              <div className="loader-container1">
                <ThreeDots color="#00BFFF" height={60} width={60} />
              </div>
            )}
          </div>
        )}
        {activeTab === "services" && (
          <div className="no-offers">
            <h2>There is no offer at the moment.</h2>
            <FontAwesomeIcon icon={faFaceMeh} className="no-offer-icon" />
            <p>
              Don't worry, you will get notified as soon as someone requested
              you! In the meantime, you can access our list of other offers
            </p>
          </div>
        )}
      </div>
      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers" className="mn-indicator">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More">
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}
