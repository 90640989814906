import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faHouse, faBroom, faBars } from '@fortawesome/free-solid-svg-icons';
import logo from "../images/FreshMClean-logo PNG 2.png"
import moment from 'moment-timezone';
import axiosInstance from "../context/axiosInstance";
import { ThreeDots } from "react-loader-spinner";

const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().tz('Africa/Lagos').format('YYYY-MM-DD')
  );
  const [currentMonth, setCurrentMonth] = useState(
    moment().tz('Africa/Lagos').month()
  );
  const [currentYear, setCurrentYear] = useState(
    moment().tz('Africa/Lagos').year()
  );
  const [appointments, setAppointments] = useState([]);
  const [allAppointments, setAllAppointments] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const userId = localStorage.getItem("uid");
        const response = await axiosInstance.get(`/getJobsByCleaner/${userId}`);
        const jobs = response.data
        const oneOffJobs = jobs.filter(job => job.status !== 'completed');
        setAllAppointments(oneOffJobs);
        const dayAppointments = jobs.filter(
          appointment => moment(appointment.desireDate).format('YYYY-MM-DD') === selectedDate
        );
        setAppointments(dayAppointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    fetchAppointments();
  }, [selectedDate]);

  const hasAppointments = (date) => {
    return allAppointments.some(appointment => moment(appointment.desireDate).format('YYYY-MM-DD') === date);
  };

  const renderDays = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const days = [];
    const daysCount = daysInMonth(currentMonth, currentYear);
    for (let i = 1; i <= daysCount; i++) {
      const day = moment(new Date(currentYear, currentMonth, i))
        .tz('Africa/Lagos')
        .format('YYYY-MM-DD');
      days.push(
        <div
          key={i}
          className={`day ${day === selectedDate ? 'selected' : ''} ${hasAppointments(day) ? 'has-appointments' : ''}`}
          onClick={() => setSelectedDate(day)}
        >
          {i}
        </div>
      );
    }
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.unshift(<div key={`empty-${i}`} className="day"></div>);
    }
    return days;
  };

  const handlePrevMonth = () => {
    setCurrentMonth(prevMonth => {
      const newMonth = prevMonth === 0 ? 11 : prevMonth - 1;
      setCurrentYear(prevYear => (newMonth === 11 ? prevYear - 1 : prevYear));
      return newMonth;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => {
      const newMonth = prevMonth === 11 ? 0 : prevMonth + 1;
      setCurrentYear(prevYear => (newMonth === 0 ? prevYear + 1 : prevYear));
      return newMonth;
    });
  };

  return (
    <div className='calendar-page'>
      <nav className="dashboard-nav cp-nav">
        <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers">
            Offers
            {/* <span className="alert">
              4
            </span> */}
          </Link>
          <Link to="/Calendar" className='indicator'>Calendar</Link>
          <Link to="/More">More</Link>
        </div>
      </nav>
      <div className='calendar-container'>
        <nav className="calendar-nav">
          <div className="month-navigation">
            <FontAwesomeIcon icon={faChevronLeft} onClick={handlePrevMonth} />
            <span>{`${moment(new Date(currentYear, currentMonth)).format('MMMM')} ${currentYear}`}</span>
            <FontAwesomeIcon icon={faChevronRight} onClick={handleNextMonth} />
          </div>
        </nav>
        <div className="calendar-space">
          <div className="calendar-header">
            <h2>My Appointments</h2>
          </div>
          <div className="calendar-month">
            {renderDays()}
          </div>
          <div className="calendar-day">
            <h3>Appointments for {selectedDate}</h3>
            {appointments.length === 0 ? (
              <p>No appointments for this date.</p>
            ) : (
              appointments.map(appointment => (
                <div 
                  key={appointment._id} 
                  className={`appointment ${appointment.status === 'execusion' ? 'execution' : appointment.status === 'completed' ? 'completed' : ''}`}
                >
                  <div><strong>Name:</strong> {appointment.address.customerName}</div>
                  <div><strong>Date & Time:</strong> {moment(appointment.desireDate).format('YYYY-MM-DD')} {appointment.desireTime}</div>
                  <div><strong>Address:</strong> {appointment.address.streetName}, {appointment.address.city}, {appointment.address.postCode}, {appointment.address.houseNumber}, {appointment.address.building}, {appointment.address.entranceNumber}</div>
                  <div><strong>Phone:</strong> {appointment.address.customerPhoneNumber}</div>
                  <div><strong>Email:</strong> {appointment.address.customerEmail}</div>
                  <div><strong>Order Type:</strong> {appointment.orderType}</div>
                  <div><strong>Order Range:</strong> {appointment.orderRange}</div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar" className='mn-indicator'>
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More">
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
};

export default Calendar;
