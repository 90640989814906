import React, { useState, useEffect } from 'react';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faHouse, faBroom, faCalendar, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFaceMeh } from '@fortawesome/free-regular-svg-icons';
import profilePic from "../images/148.png";
import axiosInstance from "../context/axiosInstance";
import logo from "../images/FreshMClean-logo PNG 2.png";

export default function Customers() {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state?.userData;
  const [activeTab, setActiveTab] = useState('repeat');
  const [repeatCleans, setRepeatCleans] = useState([]);
  const [oneOffCleans, setOneOffCleans] = useState([]);
  const cleanerId = localStorage.getItem("uid");

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axiosInstance.get(`/getJobsByCleaner/${cleanerId}`);
        const jobs = response.data;
        const completedJobs = jobs.filter(job => job.status === 'completed');
        const oneOffJobs = jobs.filter(job => job.status !== 'completed');

        setRepeatCleans(completedJobs);
        setOneOffCleans(oneOffJobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, [cleanerId]);

  return (
    <div>
      <nav className="dashboard-nav">
        <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers">
            Offers
            {/* <span className="alert">4</span> */}
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More" className='indicator'>More</Link>
        </div>
      </nav>

      <div className="more-body">
        <div className="more-header">
          <span onClick={() => navigate('/More')}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className="profile-display">
            <figure>
              <img src={profilePic} alt="" />
            </figure>
            <p>{userData ? userData.fullname : "New User"}</p>
          </div>
        </div>
        <div className="more-space">
          <div className="customers-space">
            <div className="cs-tab-btns">
              <span
                className={`rc-btn ${activeTab === 'repeat' ? 'cs-tab-indicator' : ''}`}
                onClick={() => setActiveTab('repeat')}
              >
                Repeat cleans
              </span>
              <span
                className={`oc-btn ${activeTab === 'oneOff' ? 'cs-tab-indicator' : ''}`}
                onClick={() => setActiveTab('oneOff')}
              >
                One off cleans
              </span>
            </div>
            <div className={`tab-content ${activeTab === 'repeat' ? 'active' : ''}`}>
              <div className="repeat-clean-tab">
                {repeatCleans.length > 0 ? (
                  repeatCleans.map((job) => (
                    <section className="customer" key={job._id}>
                      <div className='customer-details'>
                        <p>{job.address.customerName}</p>
                        <p>{job.orderType }</p>
                      </div>
                      <div className="earning-amount">
                        <span>{job.payment.amount}</span>
                        <span>€</span>
                      </div>
                    </section>
                  ))
                ) : (
                  <div className="no-customer">
                    <h2>There are no repeat cleans at the moment.</h2>
                    <FontAwesomeIcon icon={faFaceMeh} className='no-offer-icon'/>
                    <p>Don’t worry, you will get notified as soon as someone requests you! In the meantime, you can access our list of other offers.</p>
                  </div>
                )}
              </div>
            </div>
            <div className={`tab-content ${activeTab === 'oneOff' ? 'active' : ''}`}>
              <div className="one-off-tab">
                {oneOffCleans.length > 0 ? (
                  oneOffCleans.map((job) => (
                    <section className="customer" key={job._id}>
                      <div className='customer-details'>
                        <p>{job.address.customerName}</p>
                        <p>{job.orderType === 'individual' ? 'Full house clean' : job.orderType}</p>
                      </div>
                      <div className="earning-amount">
                        <span>{job.payment.amount}</span>
                        <span>€</span>
                      </div>
                    </section>
                  ))
                ) : (
                  <div className="no-customer">
                    <h2>There is no customer at the moment.</h2>
                    <FontAwesomeIcon icon={faFaceMeh} className='no-offer-icon'/>
                    <p>Don’t worry, you will get notified as soon as someone requests you! In the meantime, you can access our list of other offers.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse}/>
          <small>Home</small>
        </Link>
        <Link to="/Offers">
          <FontAwesomeIcon icon={faBroom}/>
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar}/>
          <small>Calendar</small>
        </Link>
        <Link to="/More" className='mn-indicator'>
          <FontAwesomeIcon icon={faBars}/>
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}
