import React from "react";
import { BrowserRouter as Router,
  Routes,
  Route } from "react-router-dom";
import CreateAccount from "./pages/CreateAccount";
import Login from "./pages/Login";
import Forgotpassword from "./pages/Forgotpassword";
import Dashboard from "./pages/Dashboard";
import Offers from "./pages/Offers";
import Calendar from "./pages/Calendar";
import More from "./pages/More";
import Notifications from "./pages/Notifications";
import References from "./pages/References";
import CleaningOffers from "./pages/CleaningOffers";
import Editprofile from "./pages/Editprofile";
import Help from "./pages/Help";
import Customers from "./pages/Customers";
import Setavailability from "./pages/Setavailability";
import TimeSlider from "./components/TimeSlider";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<CreateAccount/>}/> */}
          <Route path="/" element={<Login/>}/>
          <Route path="/Forgotpassword" element={<Forgotpassword/>}/>
          <Route path="/Dashboard" element={<Dashboard/>}/>
          <Route path="/Offers" element={<Offers/>}/>
          <Route path="/More" element={<More/>}/>
          <Route path="/Calendar" element={<Calendar/>}/>
          <Route path="/Notifications" element={<Notifications/>}/>
          <Route path="/References" element={<References/>}/>
          <Route path="/CleaningOffers" element={<CleaningOffers/>}/>
          <Route path="/Editprofile" element={<Editprofile/>}/>
          <Route path="/Help" element={<Help/>}/>
          <Route path="/Customers" element={<Customers/>}/>
          <Route path="/Setavailability" element={<Setavailability/>}/>
          <Route path="/TimeSlider" element={<TimeSlider/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
