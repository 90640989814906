import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faHouse, faBroom, faCalendar, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import profilePic from "../images/148.png";
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function Editprofile() {
  const [isEmailModalActive, setIsEmailModalActive] = useState(false);
  const [isMobileNumberModalActive, setIsMobileNumberModalActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state?.userData;

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setIsEmailModalActive(false);
  };

  const handleMobileNumberSubmit = (e) => {
    e.preventDefault();
    setIsMobileNumberModalActive(false);
  };

  return (
    <div>
       <nav className="dashboard-nav">
            <img src={logo} alt="" className='logo-pic'/>
            <div className="dn-links">
                <Link to="/Dashboard">Home</Link>
                <Link to="/Offers">
                    Offers
                    {/* <span className="alert">
                        4
                    </span> */}
                </Link>
                <Link to="/Calendar">Calendar</Link>
                <Link to="/More" className='indicator'>More</Link>
            </div>
        </nav>

      <div className="more-body">
        <div className="more-header">
          <span onClick={() => navigate('/More')}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className="profile-display">
            <figure>
              <img src={profilePic} alt="" />
            </figure>
            <p>{userData ? userData.fullname : "New User"}</p>
          </div>
        </div>
        <div className="more-space">
          <div className="edit-account-space">
            <h1>Edit Account</h1>
            <div className='email-modal-btn' onClick={() => setIsEmailModalActive(true)}>
              <p>Email</p>
              <span>
              <h5>{userData ? userData.email : "Johndoe@gmail.com"}</h5>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div className='mobile-number-modal-btn' onClick={() => setIsMobileNumberModalActive(true)}>
              <p>Mobile Number</p>
              <span>
                <h5>+557474838383</h5>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div>
              <p>Date of Birth</p>
              <small>88 Mar 1993</small>
            </div>
            <div>
              <p>Place of Birth</p>
              <small>jos Nigeria</small>
            </div>
            <div>
              <p>Annual Overview</p>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="ea-btn">
            Edit your public profile
          </div>
        </div>
      </div>

      {isEmailModalActive && (
        <div className="email-wrapper">
          <div className="email-modal">
            <FontAwesomeIcon icon={faXmark} className='mail-cancel' onClick={() => setIsEmailModalActive(false)} />
            <h2>Edit email</h2>
            <form onSubmit={handleEmailSubmit}>
              <div>
                <small>E-Mail</small>
                <input type="email" defaultValue={userData ? userData.email : "johndoe@gmail.com"} />
              </div>
              <button type="submit">Save changes</button>
            </form>
          </div>
        </div>
      )}

      {isMobileNumberModalActive && (
        <div className="mobile-number-wrapper">
          <div className="mobile-number-modal">
            <FontAwesomeIcon icon={faXmark} className='mn-cancel' onClick={() => setIsMobileNumberModalActive(false)} />
            <h2>Edit Mobile number</h2>
            <form onSubmit={handleMobileNumberSubmit}>
              <div>
                <small>mobile number</small>
                <input type="tel" defaultValue="+4917611805208" />
              </div>
              <button type="submit">Save changes</button>
            </form>
          </div>
        </div>
      )}

        <div className="mobile-nav">
            <Link to="/Dashboard">
                <FontAwesomeIcon icon={faHouse}/>
                <small>Home</small>
            </Link>
            <Link to="/Offers">
                <FontAwesomeIcon icon={faBroom}/>
                <small>Offers</small>
                <span>4</span>
            </Link>
            <Link to="/Calendar">
                <FontAwesomeIcon icon={faCalendar}/>
                <small>Calendar</small>
            </Link>
            <Link to="/More" className='mn-indicator'>
                <FontAwesomeIcon icon={faBars}/>
                <small>More</small>            
            </Link>
        </div>
    </div>
  );
}
