import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faBell, faHandshake, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faHouse, faBroom, faBars, faBriefcase, faArrowRight, faCircleQuestion, faUsers } from '@fortawesome/free-solid-svg-icons';
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function References() {
  const navigate = useNavigate();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toBack = () => {
    navigate('/Dashboard');
  };

  const copyToClipboard = () => {
    const linkText = document.querySelector('.rs-ref-link').textContent;
    navigator.clipboard.writeText(linkText).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };

  return (
    <div>
      <nav className="dashboard-nav nd-nav" id="ref-nav">
        <h3 className="notification-logo">
          <FontAwesomeIcon icon={faChevronLeft} onClick={toBack} />
          <p>References</p>
        </h3>
        <div className="dn-links">
          <Link to="/Dashboard">Home</Link>
          <Link to="/Offers">
            Offers
            <span className="alert">4</span>
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More">More</Link>
        </div>
      </nav>
      <div className="references-main">
        <div className="ref-header">My References</div>
        <div className="references-space">
          <h1 className="rs-subheading">Request references to enhance your profile.</h1>
          <FontAwesomeIcon icon={faUsers} className="rs-icon" />
          <p className="rs-text">
            Reviews are an important part of your profile. Customers highly value them. Request references from your past employers or customers you had before your registered on Helpling to make your profile more attractive.
          </p>
          <small className="rs-sub-text">
            Simply copy and paste the perzonalized link below and share.
          </small>
          <div className="rs-link">
            <span className="rs-ref-link">https://services.helpling.de/references/03e6e82a7721b8</span>
            <span className="rs-copy" onClick={copyToClipboard}>
              Copy
              {tooltipVisible && <div className="tooltip">Copied!</div>}
            </span>
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <Link to="/Dashboard">
          <FontAwesomeIcon icon={faHouse} />
          <small>Home</small>
        </Link>
        <Link to="/Offers">
          <FontAwesomeIcon icon={faBroom} />
          <small>Offers</small>
          <span>4</span>
        </Link>
        <Link to="/Calendar">
          <FontAwesomeIcon icon={faCalendar} />
          <small>Calendar</small>
        </Link>
        <Link to="/More">
          <FontAwesomeIcon icon={faBars} />
          <small>More</small>
        </Link>
      </div>
    </div>
  );
}
