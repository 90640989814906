import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/FreshMClean-logo PNG 2.png"

const NavBar = () => {
  return (
    <div>
         <nav className="dashboard-nav">
        <img src={logo} alt="" className='logo-pic'/>
        <div className="dn-links">
          <Link to="/Dashboard" className="indicator">
            Home
          </Link>
          <Link to="/Offers">
            Offers
            {/* <span className="alert">4</span> */}
          </Link>
          <Link to="/Calendar">Calendar</Link>
          <Link to="/More">More</Link>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
