import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBell, faHandshake, faCalendar, faHouse, faBroom, faBars, faXmark, faDownload } from '@fortawesome/free-solid-svg-icons';
import profilePic from "../images/148.png";
import axiosInstance from "../context/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import logo from "../images/FreshMClean-logo PNG 2.png"

export default function More() {
    const navigate = useNavigate();
    const handleNavigate = (path, state = {}) => {
        navigate(path, { state });
    };


  const [isChangeLanguageModalActive, setIsChangeLanguageModalActive] = useState(false);
  const [isDeactivateAccountModalActive, setIsDeactivateAccountModalActive] = useState(false);
  const [isEditLocationModalActive, setIsEditLocationModalActive] = useState(false);

  const handleEditLocationSubmit = (e) => {
    e.preventDefault();
    setIsEditLocationModalActive(false);
  };

  const handleLogout = () => {
    // Clear the local storage
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
  
    // Redirect to the login page
    navigate('/');
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("uid"); // Retrieve the user ID from localStorage
        const response = await axiosInstance.get(`/cleaners/${userId}`);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);


  return (
    <div className='dashboard'>
        <nav className="dashboard-nav">
            <img src={logo} alt="" className='logo-pic'/>
            <div className="dn-links">
                <Link to="/Dashboard">Home</Link>
                <Link to="/Offers">
                    Offers
                    {/* <span className="alert">
                        4
                    </span> */}
                </Link>
                <Link to="/Calendar">Calendar</Link>
                <Link to="/More" className='indicator'>More</Link>
            </div>
        </nav>

        <div className="more-body">
            <div className="more-header">
                <div className="profile-display">
                    <figure>
                        <img src={profilePic} alt="" />
                    </figure>
                    <p>
                    {userData ? (
              ` ${userData.fullname}!`
            ) : (
              <div className="loader-container1">
                <ThreeDots color="#FFFFFF" height={40} width={40} />
              </div>
            )}
                    </p>
                </div>
            </div>
            <div className="more-space">
                <div className="ms-section">
                    <h2 className='ms-section-subheading'>
                        Profile
                    </h2>
                    <div>
                    <section onClick={() => handleNavigate('/Editprofile', { userData })}>
                            <p>Edit Profile</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                        <section className='edit-location-btn' onClick={() => setIsEditLocationModalActive(true)}>
                            <p>Edit my location</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                        <section className='set-availability-page'
                        onClick={() => handleNavigate('/Setavailability', { userData })}>
                            <p>Set my availability</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                        <section className='my-customer-page'
                        onClick={() => handleNavigate('/Customers', { userData })}>
                            <p>My customer</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                    </div>
                </div>
                <div className="ms-section">
                    <h2 className='ms-section-subheading'>
                        Help Center
                    </h2>
                    <div>
                        <section className='helps-page' onClick={() => handleNavigate('/Help')}>
                            <p>Helps and FAQs</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                    </div>
                </div>
                <div className="ms-section">
                    <h2 className='ms-section-subheading'>
                        Settings
                    </h2>
                    <div>
                        <section className='change-language-btn' onClick={() => setIsChangeLanguageModalActive(true)}>
                            <p>Change Language</p>
                            <small>
                                English
                            </small>
                        </section>
                        <section>
                            <p>Privacy and policy</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                        <section>
                            <p>General terms and conditions</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                        <section className='deactivate-account-btn' onClick={() => setIsDeactivateAccountModalActive(true)}>
                            <p>Deactivate account</p>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </section>
                    </div>
                </div>

                <p to="#" onClick={handleLogout} className='logout-btn'>
        Log Out
      </p>
            </div>
        </div>

        {isEditLocationModalActive && (
            <div className="el-wrapper">
                <div className="edit-location-modal">
                    <FontAwesomeIcon icon={faXmark} className='el-cancel' onClick={() => setIsEditLocationModalActive(false)} />
                    <h2>Edit Address</h2>
                    <form onSubmit={handleEditLocationSubmit}>
                        <div>
                            <small>Street name and house number</small>
                            <input type="text" />
                        </div>
                        <div>
                            <small>Postal code</small>
                            <input type="tel" />
                        </div>
                        <div>
                            <small>Town/City</small>
                            <input type="text" />
                        </div>
                        <button type="submit">
                            Save Changes
                        </button>
                    </form>
                </div>
            </div>
        )}

        {isChangeLanguageModalActive && (
          <div className="cl-wrapper">
              <div className="change-language-modal">
                  <FontAwesomeIcon icon={faXmark} className='cl-cancel' onClick={() => setIsChangeLanguageModalActive(false)} />
                  <h2>
                      App Language
                  </h2>
                  <small>
                      Select a language for your app
                  </small>
                  <div>
                      <select>
                          <option value="">English</option>
                          <option value="">Spanish</option>
                          <option value="">French</option>
                      </select>
                  </div>
              </div>
          </div>
        )}

        {isDeactivateAccountModalActive && (
          <div className="da-wrapper">
              <div className="deactivate-account-modal">
                  <FontAwesomeIcon icon={faXmark} className='da-cancel' onClick={() => setIsDeactivateAccountModalActive(false)} />
                  <h4>Deactivate Account</h4>
                  <h1>Have you downloaded all your invoices?</h1>
                  <FontAwesomeIcon icon={faDownload} className='da-download-icon'/>
                  <p>
                      If you have not yet downloaded all your invoices, we recommend you do so now to save all your payments. 
                  </p>
                  <div className="da-btns">
                      <span onClick={() => setIsDeactivateAccountModalActive(false)}>Yes, Please proceed</span>
                      <span onClick={() => setIsDeactivateAccountModalActive(false)}>Go to invoices Download</span>
                  </div>
              </div>
          </div>
        )}

        <div className="mobile-nav">
            <Link to="/Dashboard">
                <FontAwesomeIcon icon={faHouse}/>
                <small>Home</small>
            </Link>
            <Link to="/Offers">
                <FontAwesomeIcon icon={faBroom}/>
                <small>Offers</small>
                <span>4</span>
            </Link>
            <Link to="/Calendar">
                <FontAwesomeIcon icon={faCalendar}/>
                <small>Calendar</small>
            </Link>
            <Link to="/More" className='mn-indicator'>
                <FontAwesomeIcon icon={faBars}/>
                <small>More</small>            
            </Link>
        </div>
    </div>
  )
}