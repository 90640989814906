import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const TimeSlider = () => {
  const [range, setRange] = useState([60, 1440]); // Default to 1:00 AM to 12:00 AM
  const minTimeSpan = 120; // 2 hours in minutes

  const handleSliderChange = (value) => {
    const [start, end] = value;
    if (end - start >= minTimeSpan) {
      setRange(value);
    }
  };

  const formatTime = (minutes) => {
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    mins = mins < 10 ? '0' + mins : mins;
    return `${hours}:${mins} ${ampm}`;
  };

  return (
    <div className="time-slider-container">
      <div className="time-indicators">
        <div className="time-indicator">{formatTime(range[0])}</div>
        <div className="time-indicator">{formatTime(range[1])}</div>
      </div>
      
      <Slider
        range
        min={60}
        max={1440}
        value={range}
        onChange={handleSliderChange}
        className="time-slider"
        railStyle={{ backgroundColor: '#d3d3d3', height: 10 }}
        trackStyle={[{ backgroundColor: '#1890ff', height: 10 }]}
        handleStyle={[
          { borderColor: '#1890ff', height: 20, width: 20, marginLeft: -10, marginTop: -5 },
          { borderColor: '#1890ff', height: 20, width: 20, marginLeft: -10, marginTop: -5 }
        ]}
      />
      {range[1] - range[0] === minTimeSpan && (
        <div className="warning-message">
          An availability slot of fewer than 2 hours reduces your chance of being requested by a new customer.
        </div>
      )}
    </div>
  );
};

export default TimeSlider;
